<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Link Delivery Receipt to PO
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <section class="invoice-preview-wrapper">
      <b-row
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div v-if="deliveryReceipt.attachments && deliveryReceipt.attachments.length">
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ deliveryReceipt.attachments ? ((deliveryReceipt.attachments.length).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item
                      v-for="(file, key) in deliveryReceipt.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 1)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <p
                    class="mt-1"
                  >
                    {{ deliveryReceipt.attachments ? (deliveryReceipt.attachments[currentFileIndex - 1] ? deliveryReceipt.attachments[currentFileIndex - 1].description : '') : '' }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (deliveryReceipt.attachments ? (deliveryReceipt.attachments.length) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div class="card__inner">
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <img
                        src="/nirvana-memorial-garden-logo.png"
                        alt="Logo"
                      >
                    </div>
                  </div>

                  <div
                    style="min-width: fit-content; width: fit-content;"
                    class="px-2"
                  >
                    <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                      Delivery Receipt
                    </h4>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2 text-right">
                    <h4 class="invoice-title">
                      {{ deliveryReceipt.stringID }}
                    </h4>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date">
                        <span class="semi-bold">Received By:</span> {{ deliveryReceipt.createdBy ? deliveryReceipt.createdBy.name : '' }}
                      </p>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date">
                        <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(deliveryReceipt.createdAt) }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-2 main__body po__create"
              >
                <b-row>
                  <b-col cols="md-12">
                    <b-table-simple
                      borderless
                      responsive
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th>
                            Date Received
                          </b-th>
                          <b-td>{{ dateFormat(deliveryReceipt.receivedDate) }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>
                            Time Received
                          </b-th>
                          <b-td>{{ timeFormatFromDateString(deliveryReceipt.receivedDate) }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>
                            Remarks
                          </b-th>
                          <b-td>{{ deliveryReceipt.remarks }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div v-if="deliveryReceipt.attachments && deliveryReceipt.attachments.length">
                      <iframe
                        v-show="showPDF"
                        id="attachmentpdf"
                        width="100%"
                        height="1145"
                      />
                      <b-img
                        v-show="!showPDF"
                        :src="imageSrc"
                        fluid-grow
                        alt="Fluid-grow image"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>

            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Link to Purchase Order</span>
                Ready to Link?
              </h3>
            </template>

            <b-card-text class="mb-0">
              <span>
                If you know which Purchase Order this delivery order is related to, please select either the correct PRF/PO number below:
              </span>
            </b-card-text>

            <validation-observer
              ref="deliveryReceiptLinkForm"
              #default="{invalid}"
            >
              <b-form @submit.prevent="confirmBeforeSubmit">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor"
                  vid="vendor"
                  rules="required"
                >
                  <b-form-group
                    label="Relevant Vendor*"
                    label-for="h-user-vendor"
                    :state="(errors.length > 0 || vendorValidation) ? false : null"
                  >
                    <v-select
                      id="h-user-vendor"
                      v-model="vendor"
                      label="companyName"
                      placeholder="Select an option"
                      :options="vendorOptions"
                      :reduce="companyName => companyName._id"
                      :clearable="false"
                      @input="updatePOOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="vendorValidation"
                      class="text-danger"
                    >
                      {{ vendorError }}
                    </small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  name="PR"
                  vid="pr"
                  rules="required"
                >
                  <b-form-group
                    label="Relevant PRF/PO*"
                    label-for="h-user-pr"
                    :state="(errors.length > 0 || prValidation) ? false : null"
                  >
                    <v-select
                      id="h-bookings-pr"
                      v-model="purchaseOrder"
                      label="purchaseRequest"
                      placeholder="Select an option"
                      :options="filteredPOOptions"
                      :clearable="false"
                    >
                      <!-- eslint-disable -->
                      <template #option="{ purchaseRequest }">
                        <span class="">{{ purchaseRequest.caseID }}</span>
                      </template>

                      <template #selected-option="{ purchaseRequest }">
                        <span class="">{{ purchaseRequest.caseID }}</span>
                      </template>
                      <!-- eslint-enable -->
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="prValidation"
                      class="text-danger"
                    >
                      {{ prError }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Link DR to Purchase Order</span>
                </b-button>

                <!-- Button: DOwnload -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-primary"
                  class="mb-75"
                  block
                  :to="{ name: 'purchasing-delivery-receipts-edit', params: { id: $route.params.id } }"
                >
                  Back to Editing
                </b-button>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BImg, BCardBody, BCardText, BTableSimple, BTbody, BTr, BTh, BTd, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BImg,
    BCardBody,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BDropdown,
    BDropdownItem,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      deliveryReceipt: {},
      vendor: '',
      purchaseOrder: '',
      vendorError: 'Valid date is required',
      vendorValidation: false,
      prError: 'Valid time is required',
      prValidation: false,
      vendorOptions: [],
      poOptions: [],
      filteredPOOptions: [],
      currentFileIndex: 1,
      selectedFileName: '',
      showPDF: false,
      imageSrc: '',

      // validation rules
      required,
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.from && to.query.from === 'my-dr') {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-my-delivery-receipts-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'My Delivery Receipts',
          to: '/purchasing/my-delivery-receipts/all-receipts',
        },
        {
          text: 'Link Delivery Receipt to PO',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-delivery-receipts-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Link Delivery Receipt to PO',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    }
    next()
  },
  created() {
    this.$http.get(`purchase/delivery-receipts/${this.$route.params.id}/link/options`)
      .then(response => {
        this.deliveryReceipt = response.data.deliveryReceipt
        this.vendorOptions = response.data.vendorOptions
        this.poOptions = response.data.poOptions
        this.filteredPOOptions = this.poOptions
        if (this.deliveryReceipt.attachments && this.deliveryReceipt.attachments.length > 0) {
          this.selectedFileName = this.deliveryReceipt.attachments[0].name
          this.imageSrc = this.deliveryReceipt.attachments[0].data
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    changeFileIndex(key) {
      this.currentFileIndex = key
      this.selectedFileName = this.deliveryReceipt.attachments[key - 1].name
      if (this.deliveryReceipt.attachments[key - 1].type.includes('pdf')) {
        const pdfViewerFrame2 = document.getElementById('attachmentpdf')
        pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.deliveryReceipt.attachments[key - 2].data}`)
        this.showPDF = true
      } else {
        this.imageSrc = this.deliveryReceipt.attachments[key - 1].data
        this.showPDF = false
      }
    },
    getCaseID() {
      const po = this.poOptions.find(o => o._id === this.purchaseOrder._id)
      if (po) {
        return po.purchaseRequest.caseID
      }

      return ''
    },
    updatePOOptions() {
      this.filteredPOOptions = this.poOptions.filter(item => item.vendor === this.vendor)
    },
    confirmBeforeSubmit() {
      this.$refs.deliveryReceiptLinkForm.validate().then(success => {
        if (success) {
          this.$swal({
            title: `Link to ${this.getCaseID()}?`,
            html: 'If this is the correct Purchase Order for this delivery receipt, it will be linked to the PO.',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/success.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Proceed to Link',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()

                formData.append('vendor', this.vendor)

                formData.append('purchaseOrder', this.purchaseOrder._id)

                this.$http.patch(`purchase/delivery-receipts/${this.$route.params.id}/link-to-po`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(() => {
                    this.$swal({
                      title: 'Successfully Updated!.',
                      html: `A Delivery Receipt has been created for the request ${this.getCaseID()}.`,
                      // eslint-disable-next-line global-require
                      imageUrl: require('@/assets/images/icons/save.png'),
                      imageWidth: 80,
                      imageHeight: 80,
                      imageAlt: 'Custom Icon',
                      showCancelButton: false,
                      allowOutsideClick: false,
                      confirmButtonText: 'Okay',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                      .then(confirm => {
                        if (confirm.value) {
                          const readPermission = this.user.permissions.find(o => (o.action === 'read' && o.subject === 'DeliveryReceipt') || (o.action === 'manage' && o.subject === 'all'))
                          const myPermission = this.user.permissions.find(o => (o.action === 'my-department-delivery-receipt-read' && o.subject === 'DeliveryReceipt') || (o.action === 'my-delivery-receipt-read' && o.subject === 'DeliveryReceipt'))
                          if (readPermission) {
                            this.$router.push({ name: 'purchasing-delivery-receipts-index', params: { type: 'all-receipts' } })
                          } else if (myPermission) {
                            this.$router.push({ name: 'purchasing-my-delivery-receipts-index', params: { type: 'all-receipts' } })
                          } else {
                            this.$router.replace(this.user.userRedirectURL || '/')
                              .then(() => {
                                this.$toast({
                                  component: ToastificationContent,
                                  props: {
                                    title: 'You do not have permission to view this page',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                  },
                                })
                              })
                          }
                        }
                      })
                  })
                  .catch(error => {
                    if (error.data.errors) {
                      error.data.errors.forEach(validationError => {
                        if (validationError.param === 'vendor') {
                          this.vendorError = validationError.msg
                          this.vendorValidation = true
                        } else if (validationError.param === 'purchaseOrder') {
                          this.prError = validationError.msg
                          this.prValidation = true
                        } else {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                              icon: 'AlertTriangleIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
              }
            })
        }
      })
    },
  },
}
</script>
